import { createAction, props } from '@ngrx/store';
import { Ship, ShipSearchResult, ShipUuid } from 'src/types';

// eslint-disable-next-line no-shadow
export const enum ShipActions {
  LoadShips = '[SHIP] Load Ships',
  LoadShipsSuccess = '[SHIP] Load Ships Success',
  LoadShipsFailure = '[SHIP] Load Ships Failure',
  LoadShipUuids = '[SHIP] Load Ship Uuids',
  LoadShipUuidsSuccess = '[SHIP] Load Ship Uuids Success',
  LoadShipUuidsFailure = '[SHIP] Load Ship Uuids Failure',
  GetShip = '[SHIP] Get Ship',
  GetShipSuccess = '[SHIP] Get Ship Success',
  GetShipFailure = '[SHIP] Get Ship Failure',
  CreateShip = '[SHIP] Create Ship',
  CreateShipSuccess = '[SHIP] Create Ship Success',
  CreateShipFailure = '[SHIP] Create Ship Failure',
  CreateLink = '[SHIP] Create Link',
  CreateLinkSuccess = '[SHIP] Create Link Success',
  CreateLinkFailure = '[SHIP] Create Link Failure',
  LoadLink = '[SHIP] Load Link',
  LoadLinkFromImo = '[SHIP] Load Link from IMO',
  LoadLinkSuccess = '[SHIP] Load Link Success',
  GetLinkFailure = '[SHIP] Get Link Failure',
  UpdateShip = '[SHIP] Update Ship',
  UpdateShipSuccess = '[SHIP] Update Ship Success',
  UpdateShipFailure = '[SHIP] Update Ship Failure',
  DeleteShip = '[SHIP] Delete Ship',
  DeleteShipSuccess = '[SHIP] Delete Ship Success',
  DeleteShipFailure = '[SHIP] Delete Ship Failure',
  SetSelectedShip = '[SHIP] Set Select Ship',
  SearchShips = '[SHIP] Search Ships',
  SearchShipsSuccess = '[SHIP] Search Ships Success',
  SearchShipsFailure = '[SHIP] Search Ships Failure',
}

/** Load ships */
export const loadShips = createAction(ShipActions.LoadShips);
export const loadShipsSuccess = createAction(ShipActions.LoadShipsSuccess, props<{ ships: any[] }>());
export const loadShipsFailure = createAction(ShipActions.LoadShipsFailure, props<{ error: any }>());

/** Load ship uuids */
export const loadShipUuids = createAction(ShipActions.LoadShipUuids);
export const loadShipUuidsSuccess = createAction(ShipActions.LoadShipUuidsSuccess, props<{ shipUuids: ShipUuid[] }>());
export const loadShipUuidsFailure = createAction(ShipActions.LoadShipUuidsFailure, props<{ error: any }>());

/** Get ship */
export const getShip = createAction(ShipActions.GetShip, props<{ imo: number }>());
export const getShipSuccess = createAction(ShipActions.GetShipSuccess, props<{ ship: Ship }>());
export const getShipFailure = createAction(ShipActions.GetShipFailure, props<{ error: any }>());

/** Create link */
export const createLink = createAction(ShipActions.CreateLink, props<{ imo: number; chartererReference?: string }>());
export const createLinkSuccess = createAction(
  ShipActions.CreateLinkSuccess,
  props<{ response: { status: 'Created' | 'Error'; uuid: string }; imo: number }>()
);
export const createLinkFailure = createAction(ShipActions.CreateLinkFailure, props<{ error: any }>());

/** Get link */
export const loadLink = createAction(ShipActions.LoadLink, props<{ uuid: string }>());
export const loadLinkFromImo = createAction(ShipActions.LoadLinkFromImo, props<{ imo: number; cpid?: string }>());
export const loadLinkSuccess = createAction(ShipActions.LoadLinkSuccess, props<{ shipUuid: ShipUuid }>());
export const getLinkFailure = createAction(ShipActions.GetLinkFailure, props<{ error: any }>());

/** Select ship */
export const setSelectedShip = createAction(ShipActions.SetSelectedShip, props<{ imo: number }>());

/** Search ships */
export const searchShips = createAction(ShipActions.SearchShips, props<{ term: string }>());
export const searchShipsSuccess = createAction(ShipActions.SearchShipsSuccess, props<{ ships: ShipSearchResult[] }>());
export const searchShipsFailure = createAction(ShipActions.SearchShipsFailure, props<{ error: any }>());
